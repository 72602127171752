import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Checkbox } from "antd";
import { Input, Select } from "@chakra-ui/react";
import { DatePicker as AntdDatePicker } from "antd";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FormElements, IconButton } from "../styles";
import {
  createSingleDataTables,
  editSingleDataTables,
  deleteAllSingleDataTables,
  getCurrentClientDataTable,
  getCurrentDepositDataTable,
  getCurrentLoanDataTable,
} from "../../../../../actions/utilityActions";
import { formatDate } from "../../../../../util/FormatDate";
import {
  replaceUnderscoreToText,
  replaceUnderscoreToTextOnly,
} from "../../../../../util/Tools";
import DeleteModal from "./DeleteModal";
import ModalContainer from "../../../components/ModalContainer";
import EditSvg from "../../../../../assets/images/icons/edit-profile.svg";
import DeleteSvg from "../../../../../assets/images/icons/delete.svg";
import CheckPermission from "../../../../../util/CheckPermission";

const DataTables = ({ id, dataTables, type }) => {
  const dispatch = useDispatch();

  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

  const {
    currentClientDataTableInfo,
    currentDepositDataTableInfo,
    currentLoanDataTableInfo
  } = useSelector((state) => state.manageDataTables);

  let defaultDataToSend = {
    dateFormat: "dd MMM yyyy",
    locale: "en",
  };
  const [dataToSend, setMainDataToSend] = useState({});
  const [modalState, toggleModal] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [hasOneRow, setHasOneRow] = useState(false);
  const [mainCurrentData, setMainCurrentData] = useState([]);
  const [setPostType, setSetPostType] = useState("PUT");
  const [currentMultiRowId, setCurrentMultiRowId] = useState("");
  const [currentActiveDataTable, setCurrentActiveDataTable] = useState("");
  const [currentViewState, setCurrentViewState] = useState(1);
  const [allowedDataTables, setAllowedDataTables] = useState([]);
  const [tableInfo, setTableInfo] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [multiRowIndex, setMultiRowIndex] = useState(0);
  const [isMultiRow, setIsMultiRow] = useState(false);

  useEffect(() => {
    if (currentActiveDataTable) {
      if (type === 'client') {
        dispatch(getCurrentClientDataTable(currentActiveDataTable, id));
      } else if (type === 'loan') {
        dispatch(getCurrentLoanDataTable(currentActiveDataTable, id));
      } else if (type === 'savings_account') {
        dispatch(getCurrentDepositDataTable(currentActiveDataTable, id));
      }
    }
  }, [type, id, currentActiveDataTable, refresh]);

  useEffect(() => {
    if (type === 'client') {
      setTableInfo(currentClientDataTableInfo);
    } else if (type === 'loan') {
      setTableInfo(currentLoanDataTableInfo);
    } else if (type === 'savings_account') {
      setTableInfo(currentDepositDataTableInfo);
    }
  }, [
    type,
    currentClientDataTableInfo,
    currentDepositDataTableInfo,
    currentLoanDataTableInfo
  ]);
  const { data = [], columnHeaders = [] } = tableInfo;

  useEffect(() => {
    if (
      columnHeaders &&
      columnHeaders.length &&
      columnHeaders[0] &&
      columnHeaders[0].columnName === "id"
    ) {
      setHasOneRow(false);
    } else {
      setHasOneRow(true);
    }
  }, [columnHeaders, data]);

  useEffect(() => {
    if (data && data.length) {
      setMainCurrentData(
        data && data.length && data[0] && data[0]?.row && data[0]?.row.length
          ? data[0]?.row
          : []
      );
      setSetPostType("PUT");
    } else {
      setSetPostType("POST");
    }
  }, [data]);

  const handleEditManyRowData = (index) => {
    setMultiRowIndex(index);
    setIsMultiRow(true);
    setMainCurrentData(
      data &&
        data.length &&
        data[index] &&
        data[index].row &&
        data[index].row.length
        ? data[index].row
        : []
    );
  };

  useEffect(() => {
    let wales = {};
    if (mainCurrentData && mainCurrentData.length) {
      columnHeaders &&
        columnHeaders.length &&
        columnHeaders.forEach(({ columnName, columnDisplayType,columnValues  }, index) => {          
          if (columnDisplayType === "DATETIME" || columnDisplayType === "DATE") {
            wales[columnName] = mainCurrentData[index]
              ? formatDate(mainCurrentData[index])
              : "";
            } else if (columnName !== "id" && columnName !== `${type}_id`) {
              wales[columnName] = mainCurrentData[index]
              ? mainCurrentData[index]
              : "";
          }
          if (columnName === "id") {
            setCurrentMultiRowId(mainCurrentData[index]);
          }
          if (columnDisplayType === "CODELOOKUP") {
            wales[columnName] = columnValues?.find(({ value }) => value === mainCurrentData[index])?.id
          }
          wales["dateFormat"] = "dd MMM yyyy";
          wales["locale"] = "en";
        });
    }
    
    setDataToEdit(wales);
  }, [mainCurrentData, columnHeaders]);

  const formatDataTableName = (registeredTableName) => registeredTableName?.split(" ")?.join("_")?.toUpperCase();
  const formattedPermissions = permissions?.map(permission => formatDataTableName(permission));

  useEffect(() => {
    if (dataTables.length > 0) {
      setAllowedDataTables(dataTables?.filter(dataTable => {
        return (
          CheckPermission(`READ_${formatDataTableName(dataTable?.registeredTableName)}`, formattedPermissions) ||
          CheckPermission(`CREATE_${formatDataTableName(dataTable?.registeredTableName)}`, formattedPermissions) ||
          CheckPermission(`UPDATE_${formatDataTableName(dataTable?.registeredTableName)}`, formattedPermissions) ||
          CheckPermission(`DELETE_${formatDataTableName(dataTable?.registeredTableName)}`, formattedPermissions)
        );
      }));
    }
  }, [dataTables]);

  useEffect(() => {
    if (allowedDataTables.length > 0) {
      setCurrentActiveDataTable(allowedDataTables[0]?.registeredTableName);
    }
  }, [allowedDataTables]);

  const setDataToSend = (value, colomnName, type = null) => {
    let newValue;
    if (type!== null && (type === 'DATE' || type === 'DATETIME')) {
      newValue = formatDate(value);
    } else {
      newValue = value;
    }
    let data = { ...dataToSend };
    data[colomnName] = newValue;
    setMainDataToSend(data);
  };

  const setDataToEditSend = (value, colomnName, type = null) => {
    let newValue;
    if (type!== null && (type === 'DATE' || type === 'DATETIME')) {
      newValue = formatDate(value);
    } else {
      newValue = value;
    }
    let data = { ...dataToEdit };
    data[colomnName] = newValue;
    setDataToEdit(data);
  };

  const func = () => {
    setCurrentViewState(1);
    setRefresh(refresh => refresh + 1);
  };

  const handleCreateDataTableList = () => {
    dispatch(
      createSingleDataTables(currentActiveDataTable, id, dataToSend, func)
    );
  };

  const handleDeleteAllDataTableList = () => {
    dispatch(
      deleteAllSingleDataTables(
        currentActiveDataTable,
        id,
        !hasOneRow ? currentMultiRowId : "",
        func
      )
    );
    toggleModal(false);
  };

  const handleEditDataTableList = () => {
    dispatch(
      editSingleDataTables(
        currentActiveDataTable,
        id,
        dataToEdit,
        func,
        setPostType,
        !hasOneRow ? currentMultiRowId : ""
      )
    );
  };

  const RenderTooltip = ({ text, id, children }) => (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
      >
        {children}
      </OverlayTrigger>
    </>
  );

  const handleOnHide = () => {
    toggleModal(false);
  };

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && (
          <DeleteModal
            onHide={handleOnHide}
            action={handleDeleteAllDataTableList}
            tableName={currentActiveDataTable}
          />
        )}
      </ModalContainer>
      <div className="row mt-5">
        <div className="col-md-3 p-0 box">
          <p className="my-4 ml-3 font-weight-bold">Data Tables List</p>

          <div className="data-tables">
            {allowedDataTables.map((data, index) => {
              let { registeredTableName } = data || {};
              return (
                <div
                  onClick={() => {
                    setCurrentActiveDataTable(registeredTableName);
                    setCurrentViewState(1);
                    setMainDataToSend(defaultDataToSend);
                    setDataToEdit(defaultDataToSend);
                  }}
                  key={index}
                  className={`data-table-tabs ${registeredTableName === currentActiveDataTable
                      ? "data-table-tabs-active"
                      : ""
                    }`}
                >
                  {registeredTableName}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-9 p-4 box">
          {currentViewState === 1 && (
            !hasOneRow ? (
              <>
                <div className="spaceBetweenFlexEnd">
                  {(currentActiveDataTable !== "Nuban" &&
                    CheckPermission(`CREATE_${formatDataTableName(currentActiveDataTable)}`, formattedPermissions)) && (
                      <div
                        className="btn-small mr-3"
                        onClick={() => {
                          setCurrentViewState(2);
                          setMainDataToSend(defaultDataToSend);
                          setDataToEdit(defaultDataToSend);
                        }}
                      >
                        Add
                      </div>
                    )}

                  {(currentActiveDataTable !== "Bvn" &&
                    currentActiveDataTable !== "Nuban" &&
                    CheckPermission(`DELETE_${formatDataTableName(currentActiveDataTable)}`, formattedPermissions)) && (
                      <div
                        className="btn-small red"
                        onClick={() => {
                          toggleModal(true);
                          setCurrentMultiRowId("");
                        }}
                      >
                        Delete All
                      </div>
                    )}
                </div>
                <div className="w-100 mt-4">
                  {(CheckPermission(`CREATE_${formatDataTableName(currentActiveDataTable)}`, formattedPermissions) ||
                    CheckPermission(`READ_${formatDataTableName(currentActiveDataTable)}`, formattedPermissions) ||
                    CheckPermission(`UPDATE_${formatDataTableName(currentActiveDataTable)}`, formattedPermissions) ||
                    CheckPermission(`DELETE_${formatDataTableName(currentActiveDataTable)}`, formattedPermissions)) && (
                      <Table border responsive>
                        <thead className="general-table-format">
                          <tr style={{ backgroundColor: "#EDEFF5" }}>
                            {columnHeaders.length > 0 && columnHeaders
                              ?.filter((header) => header.columnName !== "id" && header.columnName !== `${type}_id`)
                              ?.map((header, index) => (
                              <RenderTooltip
                                id={index}
                                key={index}
                                text={replaceUnderscoreToTextOnly(header.columnName)}
                              >
                                <th>{header.columnName}</th>
                              </RenderTooltip>
                            ))}
                            {(CheckPermission(
                              `UPDATE_${formatDataTableName(currentActiveDataTable)}`,
                              formattedPermissions
                            ) || CheckPermission(
                              `DELETE_${formatDataTableName(currentActiveDataTable)}`,
                              formattedPermissions
                            )) && (
                                <th>Action</th>
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.length > 0 && data.map(({ row }, index) => (
                            <tr id={index} key={index}>
                              {row.slice(2).map((value, index) => <td id={index}>{value}</td>)}
                              <td>
                                <div className="d-flex">
                                  {CheckPermission(`UPDATE_${formatDataTableName(currentActiveDataTable)}`, formattedPermissions) && (
                                    <RenderTooltip text="Edit" id="edit">
                                      <IconButton
                                        onClick={() => {
                                          setCurrentViewState(3);
                                          handleEditManyRowData(index);
                                        }}
                                        color="#2C1DFF"
                                      >
                                        <img src={EditSvg} alt="" className="icon-img" />
                                      </IconButton>
                                    </RenderTooltip>
                                  )}
                                  {CheckPermission(`DELETE_${formatDataTableName(currentActiveDataTable)}`, formattedPermissions) && (
                                    <RenderTooltip text="Delete" id="delete">
                                      <IconButton
                                        onClick={() => {
                                          setCurrentMultiRowId(row[0]);
                                          toggleModal(true);
                                        }}
                                        color="#2C1DFF"
                                      >
                                        <img src={DeleteSvg} alt="" className="icon-img" />
                                      </IconButton>
                                    </RenderTooltip>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                </div>
              </>
            ) : (
              <>
                <div className="spaceBetweenFlexEnd">
                    {CheckPermission(`UPDATE_${formatDataTableName(currentActiveDataTable)}`, formattedPermissions) && (
                      <div
                        className="btn-small mr-3"
                        onClick={() => {
                          setCurrentViewState(3);
                          setIsMultiRow(false);
                        }}
                      >
                        Edit
                      </div>
                    )}

                  {currentActiveDataTable !== "Bvn" && (
                      CheckPermission(`DELETE_${formatDataTableName(currentActiveDataTable)}`, formattedPermissions, formattedPermissions) && (
                        <div
                          onClick={() => {
                            toggleModal(true);
                            setCurrentMultiRowId("");
                          }}
                          className="btn-small red"
                        >
                          Delete
                        </div>
                      ))}
                </div>
                <div className="w-100 mt-4">
                  <Table bordered responsive>
                    <tbody>
                      {columnHeaders.length > 0 && columnHeaders.map((header, index) => {
                        if (header.columnName !== `${type}_id`) {
                          return (
                            <tr key={index}>
                              <td>{header.columnCode ?? header.columnName}</td>
                              <td>
                                {data.length > 0
                                  ? (data[0]?.row[index] ?? data[0]?.row ?? data[0])
                                  : '---'}
                              </td>
                            </tr>
                          );
                        }
                      }
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            )
          )}

          {currentViewState === 2 ? (
            <>
              <div className="row">
                <h3 style={{ fontSize: "20px" }}>
                  Add New {currentActiveDataTable}
                </h3>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ width: "60%" }}>
                    <FormElements>
                      {columnHeaders && columnHeaders.length
                        ? columnHeaders.map(
                          ({
                            columnDisplayType,
                            columnName,
                            columnValues,
                            columnCode,
                          }) => {
                            if (
                              columnName !== "id" &&
                              columnName !== `${type}_id`
                            ) {
                              if (
                                columnDisplayType === "STRING" ||
                                columnDisplayType === "TEXT" ||
                                columnDisplayType === "INTEGER" ||
                                columnDisplayType === "DECIMAL"
                              ) {
                                return (
                                  <div className="form-group d-flex justify-content-between align-items-center">
                                    <label className="text-label">
                                      {columnName}
                                    </label>
                                    <Input
                                      style={{ width: "60%" }}
                                      size="md"
                                      name={columnName}
                                      value={dataToSend[columnName]}
                                      onChange={(e) => {
                                        setDataToSend(
                                          e.target.value,
                                          columnName
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              } else if (columnDisplayType === "CODELOOKUP") {
                                return (
                                  <div className="form-group d-flex justify-content-between align-items-center">
                                    <label className="text-label">
                                      {columnCode}
                                    </label>
                                    <Select
                                      placeholder={`Select ${columnCode}`}
                                      size="md"
                                      name={columnName}
                                      value={dataToSend[columnName]}
                                      onChange={(e) => {
                                        setDataToSend(
                                          Number(e.target.value),
                                          columnName
                                        );
                                      }}
                                    >
                                      {!!columnValues.length
                                        ? columnValues.map(
                                          ({ value, id }) => {
                                            return (
                                              <option value={id} id={id}>
                                                {value}
                                              </option>
                                            );
                                          }
                                        )
                                        : null}
                                    </Select>
                                  </div>
                                );
                              } else if (columnDisplayType === "DATE" || columnDisplayType === "DATETIME") {
                                const selected = new Date(dataToSend[columnName]) == 'Invalid Date'
                                  ? null
                                  : new Date(dataToSend[columnName]);
                                return (
                                  <div className="form-group d-flex justify-content-between align-items-center">
                                    <label className="text-label">
                                      {columnName}
                                    </label>
                                    <DatePicker
                                      selected={selected}
                                      onChange={(date) => setDataToSend(date, columnName, columnDisplayType)}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      // showTimeSelect={columnDisplayType === "DATETIME"}
                                      // dateFormat={`MM/dd/yyyy${columnDisplayType === "DATETIME" ? " h:mm aa" : ""}`}
                                      // / placeholderText={`MM/dd/yyyy${columnDisplayType === "DATETIME" ? " h:mm aa" : ""}`}
                                      placeholderText="MM/dd/yyyy"
                                    /> 
                                  </div>
                                );
                              } else if (columnDisplayType === "BOOLEAN") {
                                return (
                                  <div className="form-group d-flex justify-content-between align-items-center">
                                    <label className="text-label">
                                      {columnName}
                                    </label>
                                    <div
                                      className=""
                                      style={{ width: "60%" }}
                                    >
                                      <Checkbox
                                        onChange={(e) =>
                                          setDataToSend(
                                            e.target.checked,
                                            columnName
                                          )
                                        }
                                        checked={dataToSend[columnName]}
                                      ></Checkbox>
                                    </div>
                                  </div>
                                );
                              } else {
                                return "";
                              }
                            }
                          }
                        )
                        : ""}
                    </FormElements>
                  </div>
                </div>
              </div>
              <div className="spaceBetweenFlexEnd">
                <div
                  className="btn-small mr-3"
                  onClick={() => setCurrentViewState(1)}
                >
                  Cancel
                </div>
                <div className="btn-small" onClick={handleCreateDataTableList}>
                  Submit
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {currentViewState === 3 ? (
            <>
              <div className="row">
                <h3 style={{ fontSize: "20px" }}>
                  Edit {currentActiveDataTable}
                </h3>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ width: "60%" }}>
                    <FormElements>
                      {columnHeaders && columnHeaders.length
                        ? columnHeaders.map(
                          (
                            {
                              columnDisplayType,
                              columnName,
                              columnValues,
                              columnCode,
                            },
                            index
                          ) => {
                            if (
                              columnName !== "id" &&
                              columnName !== `${type}_id`
                            ) {
                              if (
                                columnDisplayType === "STRING" ||
                                columnDisplayType === "TEXT" ||
                                columnDisplayType === "INTEGER" ||
                                columnDisplayType === "DECIMAL"
                              ) {
                                return (
                                  <div className="form-group d-flex justify-content-between align-items-center">
                                    <label className="text-label">
                                      {columnName}
                                    </label>
                                    <Input
                                      style={{ width: "60%" }}
                                      size="md"
                                      name={columnName}
                                      defaultValue={data.length > 0 && isMultiRow
                                        ? (data[multiRowIndex]?.row[index])
                                        : ''}
                                      value={dataToEdit[columnName]}
                                      onChange={(e) => {
                                        setDataToEditSend(
                                          e.target.value,
                                          columnName
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              } else if (columnDisplayType === "CODELOOKUP") {
                                return (
                                  <div className="form-group d-flex justify-content-between align-items-center">
                                    <label className="text-label">
                                      {columnCode}
                                    </label>
                                    <Select
                                      placeholder={`Select ${columnCode}`}
                                      size="md"
                                      name={columnName}
                                      defaultValue={dataToEdit[columnName]}
                                      onChange={(e) => {
                                        setDataToEditSend(
                                          Number(e.target.value),
                                          columnName
                                        );
                                      }}
                                    >
                                      {!!columnValues.length
                                        ? columnValues.map(
                                          ({ value, id }) => {
                                            return (
                                              <option value={id} id={id}>
                                                {value}
                                              </option>
                                            );
                                          }
                                        )
                                        : null}
                                    </Select>
                                  </div>
                                );
                              } else if (columnDisplayType === "DATE" || columnDisplayType === "DATETIME") {
                                const selected = new Date(dataToEdit[columnName]) == 'Invalid Date'
                                  ? null
                                  : new Date(dataToEdit[columnName]);
                                return (
                                  <div className="form-group d-flex justify-content-between align-items-center">
                                    <label className="text-label">
                                      {columnName}
                                    </label>
                                    <DatePicker
                                      selected={selected}
                                      onChange={(date) => setDataToEditSend(date, columnName, columnDisplayType)}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      // showTimeSelect={columnDisplayType === "DATETIME"}
                                      // dateFormat={`MM/dd/yyyy${columnDisplayType === "DATETIME" ? " h:mm aa" : ""}`}
                                      // / placeholderText={`MM/dd/yyyy${columnDisplayType === "DATETIME" ? " h:mm aa" : ""}`}
                                      placeholderText="MM/dd/yyyy"
                                    /> 
                                  </div>
                                );
                              } else if (columnDisplayType === "BOOLEAN") {
                                return (
                                  <div className="form-group d-flex justify-content-between align-items-center">
                                    <label className="text-label">
                                      {columnName}
                                    </label>
                                    <div
                                      className=""
                                      style={{ width: "60%" }}
                                    >
                                      <Checkbox
                                        onChange={(e) =>
                                          setDataToEditSend(
                                            e.target.checked,
                                            columnName
                                          )
                                        }
                                        checked={dataToEdit[columnName]}
                                      ></Checkbox>
                                    </div>
                                  </div>
                                );
                              } else {
                                return "";
                              }
                            }
                          }
                        )
                        : ""}
                    </FormElements>
                  </div>
                </div>
              </div>
              <div className="spaceBetweenFlexEnd">
                <div
                  className="btn-small mr-3"
                  onClick={() => setCurrentViewState(1)}
                >
                  Cancel
                </div>
                <div className="btn-small" onClick={handleEditDataTableList}>
                  Submit
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default DataTables;
