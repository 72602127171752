import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

import { ContentWrapper } from "../styles";

import DeleteModal from "./DeleteModal";
import ModalContainer from "../../../components/ModalContainer";

import DeleteSvg from "../../../../../assets/images/icons/delete.svg";
import EditSvg from "../../../../../assets/images/icons/edit-profile.svg";

import { getASingleDataTable } from "../../../../../actions/configurationActions";

import { replaceUnderscoreToTextOnly } from "../../../../../util/Tools";
import CheckPermission from "../../../../../util/CheckPermission";

const DataTableDetails = ({ match, history }) => {
  const dispatch = useDispatch();

  const dataTablesData = useSelector((state) => state.dataTables);
  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;
  let { oneDataTable = {} } = dataTablesData || {};

  let {
    registeredTableName = "",
    columnHeaderData = [],
    applicationTableName = "",
  } = oneDataTable || {};

  useEffect(() => {
    dispatch(getASingleDataTable(match.params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalState, toggleModal] = useState(false);

  const handleOnHide = () => {
    toggleModal(false);
  };

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && (
          <DeleteModal
            onHide={handleOnHide}
            name={registeredTableName}
            history={history}
          />
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6">
            <h3 className="page-title">Manage Data Tables</h3>
            <p className="sub-title">
              Add new extra fields to any entity in the form of data tables
            </p>
            <p className="font-weight sub-title">
              <span className="text-info">
                <Link to="/core/manage-data-tables">Manage Data Tables</Link>
              </span>
              / {registeredTableName}
            </p>
          </div>
        </div>
        <ContentWrapper>
          <div className="row pt-4 pr-5 pb-3">
            <div className="col-md-6 pl-5">
              <div>
                <p className="page-title">{registeredTableName}</p>
                <p className="">
                  <span className="headerSpanBal">Associated With:</span>{" "}
                  {replaceUnderscoreToTextOnly(
                    applicationTableName.substring(2)
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="spaceBetweenFlexEnd">
                {CheckPermission("UPDATE_DATATABLE", permissions) && (
                  <div
                    className="btn-small mr-3 d-flex"
                    onClick={() =>
                      history.push(
                        `/core/manage-data-tables/edit/${match.params.id}`
                      )
                    }
                  >
                    <img src={EditSvg} className="mr-2" alt="" /> Edit
                  </div>
                )}
                {CheckPermission("DELETE_DATATABLE", permissions) && (
                  <div
                    className="btn-small red d-flex"
                    onClick={() => toggleModal(true)}
                  >
                    <img src={DeleteSvg} className="mr-2" alt="" /> Delete
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row pt-0 pr-5 pb-3 pl-5">
            <Table className="child-table">
              <thead>
                <tr style={{ backgroundColor: "#FAFAFA" }}>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Length</th>
                  <th>Code</th>
                  <th>Mandatory</th>
                </tr>
              </thead>
              <tbody>
                {columnHeaderData?.map(
                    (
                      {
                        columnCode,
                        columnLength,
                        columnName,
                        columnDisplayType,
                        isColumnNullable,
                      },
                      index
                    ) => {
                      if (columnName !== "client_id") {
                        return (
                          <tr key={index}>
                            <td>{replaceUnderscoreToTextOnly(columnName)}</td>
                            <td>{columnDisplayType}</td>
                            <td>{columnLength}</td>
                            <td>{columnCode}</td>
                            <td>{`${!isColumnNullable}`}</td>
                          </tr>
                        );
                      } else {
                        return "";
                      }
                    }
                  )}
              </tbody>
            </Table>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};

export default DataTableDetails;
