import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";

import { Input, Select } from "@chakra-ui/react";
import { Checkbox, Tooltip } from "antd";

import { getCodeValues } from "../../../../../actions/utilityActions";

import {
  ContentWrapper,
  FormElements,
  IconButton,
  ActionButton,
} from "../styles";

import AddSvg from "../../../../../assets/images/icons/add.svg";

import Toast from "../../../../../util/Toast";
import { createADataTable } from "../../../../../actions/configurationActions";

const CreateDataTables = ({ history }) => {
  const dispatch = useDispatch();

  const allCodeValues = useSelector((state) => state.codeValues);
  let { codeValues } = allCodeValues;

  useEffect(() => {
    dispatch(getCodeValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [datatableName, setDatatableName] = useState("");
  const [apptableName, setApptableName] = useState("");
  const [multiRow, setMultiRow] = useState(false);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [checkedRow, setCheckedRow] = useState('');

  const [newColomnTables, setNewColomnTables] = useState([]);
  
  const handleRowCheck = cellName => {
    setCheckedRow(state => state === '' ? cellName : '');
  }
  
  const addDataTables = () => {
    if (!name) {
      dispatch(Toast({ text: "Please Add a Column Name", icon: "info" }));
    } else if (!type) {
      dispatch(Toast({ text: "Please Add a Column Type", icon: "info" }));
    } else {
      setName("");
      setType("");
      setNewColomnTables([
        ...newColomnTables,
        { name, type, mandatory: false },
      ]);
    }
  };

  const handleDataTableName = (value, index) => {
    let dataTableName = [...newColomnTables];
    dataTableName[Number(index)].name = value;
    setNewColomnTables(dataTableName);
  };

  const handleDataTableType = (value, index) => {
    let dataTableType = [...newColomnTables];
    dataTableType[Number(index)].type = value;
    setNewColomnTables(dataTableType);
  };

  const handleDataTableMandatory = (value, index) => {
    let dataTableMandatory = [...newColomnTables];
    dataTableMandatory[Number(index)].mandatory = value;
    setNewColomnTables(dataTableMandatory);
  };
  const handleDataTableUnique = (value, index) => {
    let dataTableMandatory = [...newColomnTables];
    dataTableMandatory[Number(index)].unique = value;
    setNewColomnTables(dataTableMandatory);
  };
  const handleDataTableSearchBy = (value, index) => {
    let dataTableMandatory = [...newColomnTables];
    dataTableMandatory[Number(index)].searchColumn = value;
    setNewColomnTables(dataTableMandatory);
  };

  const handleDataTableLength = (value, index) => {
    let dataTableLength = [...newColomnTables];
    dataTableLength[Number(index)].length = value;
    setNewColomnTables(dataTableLength);
  };

  const handleDataTableCode = (value, index) => {
    let dataTableCode = [...newColomnTables];
    dataTableCode[Number(index)].code = value;
    setNewColomnTables(dataTableCode);
  };

  const handleChargeDelete = (id) => {
    setNewColomnTables(newColomnTables.filter((item, index) => index !== id));
  };

  const handleSubmit = () => {
    if (!datatableName) {
      dispatch(Toast({ text: "Please Add a Data Table Name", icon: "info" }));
    } else if (!apptableName) {
      dispatch(
        Toast({ text: "Please Add an Applicable Table Name", icon: "info" })
      );
    } else {
      let columns = [];
      newColomnTables.length > 0 &&
        newColomnTables.forEach((colomnTable) => {
          let { name, type, mandatory, length, code, unique } = colomnTable;

          if (type === "Dropdown") {
            columns.push({
              name,
              type,
              mandatory,
              code,
              unique,
            });
          } else {
            columns.push({
              name,
              type,
              mandatory,
              length,
              unique,
            });
          }
        });

      let dataToSend = checkedRow ? {
        datatableName,
        apptableName,
        multiRow,
        columns,
        searchColumn : checkedRow,
      } : {
        datatableName,
        apptableName,
        multiRow,
        columns,
      };

      dispatch(createADataTable(dataToSend, history));
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6">
            <h3 className="page-title">Manage Data Tables</h3>
            <p className="sub-title">
              Add new extra fields to any entity in the form of data tables
            </p>
            <p className="font-weight sub-title">
              <span className="text-info">
                <Link to="/core/manage-data-tables">Manage Data Tables</Link>
              </span>{" "}
              / Create Data Tables
            </p>
          </div>
        </div>
        <ContentWrapper>
          <FormElements>
            <div className="p-5 h-100">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Data Table Name*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      name="datatableName"
                      value={datatableName}
                      onChange={(e) => setDatatableName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">
                      Application Table Name*
                    </label>
                    <Select
                      placeholder="Select One"
                      size="md"
                      name="apptableName"
                      value={apptableName}
                      onChange={(e) => setApptableName(e.target.value)}
                    >
                      <option value="m_client">Client</option>
                      <option value="m_group">Group</option>
                      <option value="m_center">Center</option>
                      <option value="m_loan">Loan</option>
                      <option value="m_office">Office</option>
                      <option value="m_savings_account">Savings Account</option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Multi Row</label>
                    <div className="" style={{ width: "60%" }}>
                      <Checkbox
                        onChange={(e) => setMultiRow(!multiRow)}
                        value={multiRow}
                      ></Checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Add Columns*</label>
                    <Input
                      style={{ width: "60%" }}
                      size="md"
                      placeholder="Column Name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex">
                    <Select
                      placeholder="Select Colomn Type"
                      size="md"
                      name="type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="String">String</option>
                      <option value="Number">Number</option>
                      <option value="Decimal">Decimal</option>
                      <option value="Boolean">Boolean</option>
                      <option value="Date">Date</option>
                      <option value="DateTime">Date & time</option>
                      <option value="Dropdown">DropDown</option>
                    </Select>
                    <div
                      onClick={() => addDataTables()}
                      className="btn-small ml-3"
                    >
                      <img
                        src={AddSvg}
                        className="icon-img"
                        alt=""
                        style={{ width: "20px", marginTop: "3px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {newColomnTables && newColomnTables.length ? (
                <>
                  <div className="row">
                    <div className="col-2">
                      <p className="fs-12 mt-2">Columns</p>
                    </div>
                    <div className="col-10 dataTables">
                      <Table className="child-table" border={1}>
                        <thead>
                          <tr style={{ backgroundColor: "#FAFAFA" }}>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Mandatory</th>
                            {/* <th>Unique</th> */}
                            <th className="d-flex align-items-center">
                              Search by
                              <Tooltip title="When this column is checked, you would be able to search by that column name">
                                <img
                                  src={require("../../../../../assets/images/icons/info-circle-2.svg").default}
                                  width={18}
                                  height={18}
                                  alt="tooltip icon"
                                  className="ml-1 pointer"
                                />
                              </Tooltip>
                            </th>
                            <th>Length</th>
                            <th>Code</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newColomnTables?.length > 0 &&
                            newColomnTables.map(
                              ({ name, type, length, code }, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Input
                                        style={{ width: "100%" }}
                                        size="md"
                                        name="datatableName"
                                        value={name}
                                        onChange={(e) =>
                                          handleDataTableName(
                                            e.target.value,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        placeholder="Select Colomn Type"
                                        size="md"
                                        name="type"
                                        value={type}
                                        style={{ width: "100% !important" }}
                                        onChange={(e) =>
                                          handleDataTableType(
                                            e.target.value,
                                            index
                                          )
                                        }
                                      >
                                        <option value="String">String</option>
                                        <option value="Number">Number</option>
                                        <option value="Decimal">Decimal</option>
                                        <option value="Boolean">Boolean</option>
                                        <option value="Date">Date</option>
                                        <option value="DateTime">
                                          Date & time
                                        </option>
                                        <option value="Dropdown">
                                          DropDown
                                        </option>
                                      </Select>
                                    </td>
                                    <td>
                                      <Checkbox
                                        onChange={(e) =>
                                          handleDataTableMandatory(
                                            e.target.checked,
                                            index
                                          )
                                        }
                                      ></Checkbox>
                                    </td>{" "}
                                    {/* <td>
                                      <Checkbox
                                        onChange={(e) =>
                                          handleDataTableUnique(
                                            e.target.checked,
                                            index
                                          )
                                        }
                                      ></Checkbox>
                                    </td> */}
                                    <td>
                                      <Checkbox
                                        disabled={checkedRow !== '' && name !== checkedRow}
                                        checked={name === checkedRow}
                                        onChange={() => handleRowCheck(name)}
                                      ></Checkbox>
                                    </td>
                                    <td>
                                      {type === "String" ? (
                                        <>
                                          <Input
                                            style={{ width: "100%" }}
                                            size="md"
                                            name="length"
                                            value={length}
                                            onChange={(e) =>
                                              handleDataTableLength(
                                                e.target.value,
                                                index
                                              )
                                            }
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {type === "Dropdown" ? (
                                        <>
                                          <Select
                                            placeholder="Select Colomn Type"
                                            size="md"
                                            name="code"
                                            value={code}
                                            style={{ width: "100% !important" }}
                                            onChange={(e) =>
                                              handleDataTableCode(
                                                e.target.value,
                                                index
                                              )
                                            }
                                          >
                                            {codeValues &&
                                              codeValues.length &&
                                              codeValues.map(({ name, id }) => {
                                                return (
                                                  <option value={name} id={id}>
                                                    {name}
                                                  </option>
                                                );
                                              })}
                                          </Select>{" "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      <IconButton
                                        onClick={() =>
                                          handleChargeDelete(index)
                                        }
                                        id="deleteHoliday"
                                        className="mr-1 mb-1"
                                      >
                                        <img
                                          src={
                                            require("../../../../../assets/images/icons/delete.svg")
                                              .default
                                          }
                                          alt="add"
                                          className="icon-img"
                                        />
                                      </IconButton>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="row">
                <div className="spaceBetweenFlexEnd w-100">
                  <ActionButton onClick={handleSubmit} className="mb-3">
                    Submit
                  </ActionButton>
                </div>
              </div>
            </div>
          </FormElements>
        </ContentWrapper>
      </div>
    </>
  );
};

export default CreateDataTables;
