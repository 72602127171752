import React, { useRef, useEffect, useState } from "react";
import { Table } from "antd";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { ActionButton, IconButton } from "../../styles.jsx";
import DownloadRepaymentSchedule from "../../CustomerInformation/components/accounts/DownloadRepaymentSchedule";
import ModalContainer from "../../../components/ModalContainer";
import { getLoanAccountCharges } from "../../../../../actions/SavingsAndLoanActions";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../../util/FormatDate";
import PayLoansChargeModal from "../PayLoansChargeModal";
import WaiveLoansChargeModal from "../WaiveLoansChargeModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CheckPermission from "../../../../../util/CheckPermission.js";

const columns = [
  { title: "Name", dataIndex: "name", key: "name" },
  {
    title: "Fee/Penalty",
    dataIndex: "feeOrPenalty",
    key: "feeOrPenalty",
  },
  { title: "Payment Due At", dataIndex: "dueAt", key: "dueAt" },
  { title: "Due As Of", dataIndex: "dueAsof", key: "dueAsof" },
  {
    title: "Applied Date",
    dataIndex: "appliedDate",
    key: "appliedDate",
  },
  {
    title: "Calculation Type",
    dataIndex: "calculationType",
    key: "calculationType",
  },
  {
    title: "Due",
    dataIndex: "due",
    key: "due",
  },
  {
    title: "Paid",
    dataIndex: "paid",
    key: "paid",
  },
  {
    title: "Waived",
    dataIndex: "waived",
    key: "waived",
  },
  {
    title: "Outstanding",
    dataIndex: "outstanding",
    key: "outstanding",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const LoanCharges = ({ charges = [], accountNo }) => {
  const [modalState, setModalState] = useState(false);
  const [modalContentName, setModalContentName] = useState("");
  const selecetedAmountOutstanding = useRef("");
  const selecetedTableIndex = useRef();
  const selectedChargedId = useRef();

  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  let { loanDetails = {} } = savingsAndLoan || {};
  let { id } = loanDetails || {};

  const isTableLoading = useSelector(
    (state) => state.isTableLoading.isTableLoading
  );
  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

  // currentContent
  const currentContent = (e, amount, index, newId) => {
    selecetedAmountOutstanding.current = amount;
    selecetedTableIndex.current = index;
    selectedChargedId.current = newId;
    const val = e.currentTarget.id.trim();
    // setDocumentId(docId)
    setTimeout(() => {
      if (val) {
        setModalState(true);
        setModalContentName(val);
      }
    }, 300);
  };

  // currentWaiveContent
  const currentWaiveContent = (e, index, newId) => {
    selecetedTableIndex.current = index;
    selectedChargedId.current = newId;
    const val = e.currentTarget.id.trim();
    // setDocumentId(docId)
    setTimeout(() => {
      if (val) {
        setModalState(true);
        setModalContentName(val);
      }
    }, 300);
  };

  const handleModalClose = () => {
    setModalState(false);
    selecetedAmountOutstanding.current = "";
    selecetedTableIndex.current = null;
    selectedChargedId.current = null;
  };

  const displayModalContent = () => {
    switch (modalContentName) {
      case "payCharges":
        return (
          <PayLoansChargeModal
            id={id}
            newId={selectedChargedId.current}
            amount={selecetedAmountOutstanding.current}
            onHide={handleModalClose}
            selectedTableIndex={selecetedTableIndex.current}
          />
        );
      case "waiveCharges":
        return (
          <WaiveLoansChargeModal
            id={id}
            newId={selectedChargedId.current}
            onHide={handleModalClose}
            selectedTableIndex={selecetedTableIndex.current}
          />
        );
      default:
        return;
    }
  };

  let chargesData = [];

  charges.forEach((charge, index) => {
    const {
      newId = charge.id,
      amountOutstanding = 0,
      amountPaid = 0,
      amountWaived = 0,
      chargeCalculationType,
      chargeTimeType,
      dueDate,
      amount = 0,
      loanChargeCreatedDate,
      name,
      penalty,
    } = charge || {};
    // console.log(charge, 'charge')

    chargesData.push({
      name,
      feeOrPenalty: penalty ? "Penalty" : "Fee",
      dueAt: !!chargeTimeType ? chargeTimeType.value : "",
      // dueAsof: `${dueDate[2]}/${dueDate[1]}/${dueDate[0]}`,
      dueAsof: !!dueDate && !!dueDate.length ? formatDate(dueDate) : "",
      appliedDate: formatDate(loanChargeCreatedDate),
      calculationType: chargeCalculationType.value,
      due: formatCurrency(amount),
      paid: formatCurrency(amountPaid),
      waived: formatCurrency(amountWaived),
      outstanding: formatCurrency(amountOutstanding),
      action: (
        <>
          {(amountOutstanding > 0 && CheckPermission('PAY_LOANCHARGE', permissions)) ? (
            <RenderTooltip text="Pay Charges" id="payCharges">
              <IconButton
                color="#FFFFFF"
                className="mr-2 "
                id="payCharges"
                onClick={(e) =>
                  currentContent(e, amountOutstanding, index, newId)
                }
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/approveCharges.svg")
                      .default
                  }
                  alt="pay"
                  style={{
                    height: "17px",
                  }}
                />
              </IconButton>
            </RenderTooltip>
          ) : (
            ""
          )}
          {(amountOutstanding > 0 && CheckPermission('WAIVE_LOANCHARGE', permissions)) ? (
            <RenderTooltip text="Waive Charges" id="waiveCharges">
              <IconButton
                className="mr-2"
                color="#FFFFFF"
                id="waiveCharges"
                onClick={(e) => currentWaiveContent(e, index, newId)}
              >
                <img
                  src={
                    require("../../../../../assets/images/icons/waiveCharges.svg")
                      .default
                  }
                  alt="waive"
                  style={{
                    height: "17px",
                  }}
                />
              </IconButton>
            </RenderTooltip>
          ) : (
            ""
          )}
        </>
      ),
      charge,
    });
  });

  // console.log(chargesData , 'chargesData');

  // const [modalState, toggleModal] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  // const handleOnHide = () => {
  //   setModalState(false);
  // };

  const [loanCharges, setLoanCharges] = useState({});

  // console.log("ssssssss", loanChargesList, id);

  return (
    <>
      {/* <div className="spaceBetweenFlexEnd">
        <div
          className="my-4"
          onClick={(e) => {
            e.preventDefault();
            handleRefresh();
          }}
        >
          <div className="btn-small">Refresh</div>
        </div>
      </div> */}

      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      <Table
        style={{
          overflow: "hidden",
          overflowX: "scroll",
        }}
        columns={columns}
        dataSource={chargesData}
        pagination={{ defaultPageSize: 4 }}
        defaultPageSize={4}
        loading={isTableLoading}
        onRow={(r) => ({
          // onClick: () => {
          //   setLoanCharges(r);
          //   setModalState(true);
          // },
        })}
      />
    </>
  );
};

const RenderTooltip = ({ text, id, children }) => (
  <>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  </>
);

export default LoanCharges;
