import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { formatCurrency } from "../../../../../util/FormatAmount";
import { ActionButton, NewActionButton } from "../../styles.jsx";
import DownloadRepaymentSchedule from "../../../Customer/CustomerInformation/components/accounts/DownloadRepaymentSchedule";
import ModalContainer from "../../../components/ModalContainer";
import EditRepaymentSchedule from "../../CustomerInformation/components/accounts/EditRepaymentSchedule.jsx";
import { formatDate } from "../../../../../util/FormatDate.js";
import { useDispatch, useSelector } from "react-redux";
import { downloadRepaymentSchedule, resetDownloadRepaymentScheduleState } from "../../../../../actions/customerActions.js";

const RepaymentSchedule = ({ repaymentSchedule, accountNo, loanId }) => {  
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  let { periods = [] } = repaymentSchedule || {};

  const dispatch = useDispatch();
  const repaymentScheduleState = useSelector((state) => state.repaymentSchedule);

  const openPdfNewTab = (file) => {
    setTimeout(() => {
      window.open(file);
    }, 2000);
  };
  
  useEffect(() => {
    const file = repaymentScheduleState.repaymentSchedule.file;
    if (file && repaymentScheduleState.repaymentSchedule?.status === "Done") {
      openPdfNewTab(file);
      dispatch(resetDownloadRepaymentScheduleState());
    }
  }, [repaymentScheduleState]);

  const toggleEditModal = () => setShowEditModal(state => !state);
  const toggleDownloadModal = () => setShowDownloadModal(state => !state);

  const columns = [
    { title: "#", dataIndex: "index", key: "index" },
    { title: "Days", dataIndex: "days", key: "days" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    { title: "Paid Date", dataIndex: "paidDate", key: "paidDate" },
    { title: "Principal Due", dataIndex: "pricipalDue", key: "pricipalDue" },
    {
      title: "Balance Of Loan",
      dataIndex: "balanceOfLoan",
      key: "balanceOfLoan",
    },
    { title: "Interest", dataIndex: "interest", key: "interest" },
    { title: "Fees", dataIndex: "fees", key: "fees" },
    { title: "Penalties", dataIndex: "penalties", key: "penalties" },
    { title: "Due", dataIndex: "due", key: "due" },
    { title: "Paid", dataIndex: "paid", key: "paid" },
    { title: "In Advance", dataIndex: "inAdvance", key: "inAdvance" },
    { title: "Late", dataIndex: "late", key: "late" },
    { title: "Outstanding", dataIndex: "outstanding", key: "outstanding" },
  ];

  let myRepaymentSchedule = [];

  let totalDays = 0;
  let totalPrincipalDue = 0;
  let totalInterest = 0;
  let totalFees = 0;
  let totalPenalties = 0;
  let totalDue = 0;
  let totalPaid = 0;
  let totalInAdvance = 0;
  let totalLate = 0;
  let totalOutstanding = 0;

  periods.forEach((repayment, index) => {
    const {
      dueDate = [],
      obligationsMetOnDate = [],
      daysInPeriod = 0,
      principalDue = 0,
      totalDueForPeriod = 0,
      principalLoanBalanceOutstanding = 0,
      interestDue = 0,
      feeChargesDue = 0,
      penaltyChargesDue = 0,
      totalPaidForPeriod = 0,
      totalPaidInAdvanceForPeriod = 0,
      totalPaidLateForPeriod = 0,
      totalOutstandingForPeriod = 0,
      complete = false,
    } = repayment;

    totalDays += daysInPeriod;
    totalPrincipalDue += principalDue;
    totalInterest += interestDue;
    totalFees += feeChargesDue;
    totalPenalties += penaltyChargesDue;
    totalDue += totalDueForPeriod;
    totalPaid += totalPaidForPeriod;
    totalInAdvance += totalPaidInAdvanceForPeriod;
    totalLate += totalPaidLateForPeriod;
    totalOutstanding += totalOutstandingForPeriod;

    myRepaymentSchedule.push({
      key: index,
      index: (
        <div className="d-flex align-items-center">
          <div
            className={
              index === 0
                ? "circle-yellow"
                : complete
                ? "circle-green"
                : "circle-red"
            }
          ></div>
          <span className="ml-2">{index + 1}</span>
        </div>
      ),
      days: daysInPeriod,
      date: formatDate(dueDate),
      paidDate: obligationsMetOnDate.length ? formatDate(obligationsMetOnDate) : "",
      pricipalDue: formatCurrency(principalDue),
      balanceOfLoan: formatCurrency(principalLoanBalanceOutstanding),
      interest: formatCurrency(interestDue),
      fees: formatCurrency(feeChargesDue),
      penalties: formatCurrency(penaltyChargesDue),
      due: formatCurrency(totalDueForPeriod),
      paid: formatCurrency(totalPaidForPeriod),
      inAdvance: formatCurrency(totalPaidInAdvanceForPeriod),
      late: formatCurrency(totalPaidLateForPeriod),
      outstanding: formatCurrency(totalOutstandingForPeriod),
    });
  });

  // myRepaymentSchedule.push({
  //   key: periods.length + 1,
  //   days: totalDays,
  //   date: "Total",
  //   pricipalDue: totalPrincipalDue.toLocaleString(),
  //   interest: totalInterest.toLocaleString(),
  //   fees: totalFees.toLocaleString(),
  //   penalties: totalPenalties.toLocaleString(),
  //   due: totalDue.toLocaleString(),
  //   paid: totalPaid.toLocaleString(),
  //   inAdvance: totalInAdvance.toLocaleString(),
  //   late: totalLate.toLocaleString(),
  //   outstanding: totalOutstanding.toLocaleString(),
  // });

  const downloadSchedule = () => {
    dispatch(downloadRepaymentSchedule(loanId));
  }

  return (
    <>
      <ModalContainer size="md" show={showEditModal || showDownloadModal}>
        {showEditModal && (
          <EditRepaymentSchedule
            loanId={loanId}
            onHide={toggleEditModal}
            periods={periods}
          />
        )}
        
        {showDownloadModal && (
          <DownloadRepaymentSchedule
            accountNo={accountNo}
            onHide={toggleDownloadModal}
          />
        )}
      </ModalContainer>

      <div className="float-right d-flex gap-2">
        <NewActionButton onClick={toggleEditModal}>
          Edit Schedule
        </NewActionButton>
        <ActionButton color="#2C1DFF" onClick={downloadSchedule}>
          Download
        </ActionButton>
      </div>
      <Table
        style={{ overflow: "scroll" }}
        columns={columns}
        dataSource={myRepaymentSchedule}
        pagination={{ defaultPageSize: 5 }}
        defaultPageSize={5}
      />
    </>
  );
};

export default RepaymentSchedule;
